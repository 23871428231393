import { useTranslation } from "next-i18next";
import image from "@/public/image.webp";
import Image from "next/image";
import AllServicesCards from "@/lib/ui/blocks/page-specific/Main/AllServices/AllServicesCardsWrapper";
import { Button } from "@/lib/ui/components/Buttons";
import styles from "./Error404.module.scss";
import Link from "next/link";
import { H1, P3 } from "@/lib/ui/components/Typography";
import { ArrowIcon } from "@/lib/ui/icons";

export const Error404 = () => {
  const { t } = useTranslation("index", { keyPrefix: "errors" });
  return (
    <section className={styles.errorPage}>
        <div className={styles.errorPage_bg_noise} />
        <div className={styles.errorPage_ellipse} />
        <div
          className={styles.errorPage_leaves}
          style={{ backgroundImage: `url(${image.src})` }}
        />
        <div className={styles.errorPage_leaf}>
          <Image 
        
        sizes="(max-width: 1200px) 0vw, 50vw"
 src={'/images/complex/leaf/white-blue-leaf.webp'} alt={""} fill />
        </div>
        <div className={styles.container}>
        <div className={styles.errorInfo}>
          <H1>404</H1>
          <P3>
            {t("somethingWrong")} <br />
            {t("quickJump")}
          </P3>
        </div>
        <AllServicesCards className={styles.cardsWrapper} />
        <div className={styles.backButton}>
          <Button
            as={Link}
            href="/"
            label={t("goBack")}
            theme={"dark-invert"}
            size={"lg"}
            icon={<ArrowIcon isRight/>}
          />
        </div>
        </div>
       
    </section>
  );
};
